import React from "react";
import InviteContent from "../../components/InviteContent";

const InviteEnPage = () => {
    return (
        <InviteContent
            title="You have been invited to join a household."
            text="Download the app to accept the invitation."
        />
    )
};

export default InviteEnPage;